import $ from 'jquery';

// DEPRECATED: This whole file is deprecated; do not use..

let hideTimer;

function closeFlashMini() {
  $('#flash_mini').fadeOut('slow');
}

/**
 * Flash mini displays an error/success message next to a element
 */
function showFlashMini(type, title, content, id) {
  // hide 'close' link if a success message
  if (type === 'success') {
    $('#flash_mini_close').hide();
  } else {
    $('#flash_mini_close').show();
  }

  if (type === 'error') {
    // eslint-disable-next-line no-param-reassign
    content = `${content}<div style='text-align:center;'><input class='closeFlashMiniBtn' type='button' value='OK' /></div>`;
    $('#flash_mini_content').on('click', 'input.closeFlashMiniBtn', () => {
      closeFlashMini();
    });
  }

  $('#flash_mini_title').html(title);
  $('#flash_mini_content').html(content);

  // remove old class add the new class after
  $('#flash_mini').css('position', 'absolute').removeClass();
  $('#flash_mini').addClass(`flash_message_${type}`);

  // position the message close to the element that called it
  const position = $(`#${id}`).position();
  const { top, left } = position;
  const width = $(`#${id}`).width();
  const height = $(`#${id}`).height();

  const msgHeight = $('#flash_mini').height();
  const msgWidth = $('#flash_mini').width();
  let msgTop = (height - msgHeight) / 3;
  if (msgTop < 0) {
    msgTop = 12;
  }

  // reposition and show mini message
  $('#flash_mini')
    .css('top', `${top + msgTop}px`)
    .css('left', `${left + (width - msgWidth) / 2}px`)
    .show();

  // fade out after 2 seconds
  // only fade out if a success message, error message leave up with a "close" link
  if (type === 'success') {
    setTimeout(() => closeFlashMini(), 2000);
  }
}

function clearSaveProgress() {
  const id = 'saveProgressContainer';
  const el = $(`#${id}`);
  const cliff = -100;

  // If we have a save progress element, hide it
  if (el.length) {
    // Clear existing timeout
    clearTimeout(hideTimer);

    // Hide the element
    el.stop();
    el.animate({ bottom: cliff }, 500);
  }
}

/**
 * simple, self-contained alerting function to show status of save progress, or other short
 * notification requirements
 */
function saveProgress(type, msg) {
  const id = 'saveProgressContainer';
  const indent = 60;
  const cliff = -100;
  let el = $(`#${id}`);
  let icon = '';
  let timer = 0;

  // Check to see if we have any old modals (not using PopupModal) that are open
  const legacyModalVisible = $('.modal.in').length;

  // If displaying an error message, clear any existing save progress alert and display a popup
  // modal
  if (!legacyModalVisible) {
    if (type === 'error' && typeof PopupModal === 'object') {
      // Clear existing alert if any
      if (el.length) {
        clearSaveProgress();
      }

      // Handle the case when the error message is an array
      if (Array.isArray(msg)) {
        // Flatten Array to get All Messages
        // eslint-disable-next-line no-param-reassign
        msg = msg.join(', ');
      }

      // Show error
      if (typeof msg !== 'undefined' && msg.length) {
        alert(msg);
      }
      return;
    }
  }

  if (!el.length) {
    el = $('<div/>')
      .attr('id', id)
      .css({
        bottom: cliff,
        position: 'fixed',
        right: indent,
        zIndex: 150001,
      })
      .appendTo('body');
  }

  // clear any existing timeouts
  clearTimeout(el.stop().data('timer'));

  // set icon based on type
  switch (type) {
    case 'info':
      icon = 'halflings-info-sign';
      timer = 10000;
      break;
    case 'success':
      icon = 'halflings-ok';
      timer = 2000;
      break;
    case 'error':
      // eslint-disable-next-line no-param-reassign
      type = 'danger';
      icon = 'halflings-remove';
      timer = 5000;
      break;
  }

  el.html(
    `<div class="save-progress alert alert-${type}"><span class="halflings ${icon}" aria-hidden="true"></span> ${msg}</div>`,
  );

  el.animate({ bottom: indent }, 500);

  // set timeout to hide
  hideTimer = setTimeout(() => {
    el.animate({ bottom: cliff }, 500);
  }, timer);
}

export { showFlashMini, closeFlashMini, clearSaveProgress, saveProgress };
